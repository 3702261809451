<template>
  <div>
    <vs-popup fullscreen title="Comandos" :active.sync="popUpEnviarComando.exibir" v-if="popUpEnviarComando.exibir">
        <EnviarComando :veiculo="popUpEnviarComando.veiculo" />
    </vs-popup>

    <vs-popup :title="`Log de Comunicação - ${popUpLogComunicacao.veiculo.placa}`" :active.sync="popUpLogComunicacao.exibir" v-if="popUpLogComunicacao.veiculo">
         <div class="flex">
          <flat-pickr v-model="popUpLogComunicacao.dataComunicacao" :config="configDatePicker" placeholder="Data"/>
          <vs-button class="ml-2" icon="close" color="primary" @click="popUpLogComunicacao.dataComunicacao = null"/>
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchLogComunicacao()" :disabled="popUpLogComunicacao.dataComunicacao === null"/>          
       </div>   
       <div class="flex mt-1">
            <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-success" class="mr-2" /> ONLINE
            <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-danger" class="ml-2 mr-2"/> OFFLINE
       </div> 
       <vue-apex-charts type="rangeBar" height="350" :options="chartOptions" :series="series"/>
        <vs-table :data="logComunicacao" class="tabela2ComScroll mt-2" noDataText="Nenhuma comunicação registrada" stripe hoverFlat >
          <template slot="thead">
              <vs-th class="p-0">Hora</vs-th>
              <vs-th class="p-0"> Status</vs-th>            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.hora}}</small></vs-td>                
                <vs-td class="p-0">
                    <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-success"  v-if="tr.status === 1" />
                    <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-danger"  v-if="tr.status === 0" />
                </vs-td>
              </vs-tr>
          </template>
       </vs-table>     
    </vs-popup> 

    <vs-popup fullscreen title="Lista de Veículos Selecionados" :active.sync="popUpSelecao.popupActive">
        <div style="white-space: pre-line" class="op-block w-full text-center" v-if="popUpSelecao.exibirMensagem">{{popUpSelecao.mensagem}}</div>
        <vx-card class="p-0">
            <div class="vx-row">
                <div class="vx-col w-1/2">
                    <div class="flex items-center">                                                                        
                        <label class="vs-input--label mr-auto">Comando</label>                      
                    </div>
                </div>
                <div class="vx-col w-1/2">
                    <label class="vs-input--label ml-2 mr-2" v-if="exibirParametro">Parâmetro</label>
                    <br/>  
                    <label class="vs-input--label ml-2 mr-2" v-if="exibirParametro">
                        <feather-icon icon="InfoIcon" svgClasses="w-4 h-4 stroke-current text-primary"/>
                        {{labelValorParametro}}
                    </label>  
                </div>
            </div>
            <div class="vx-row mt-2">
                <div class="vx-col w-1/2">                
                    <v-select                                               
                        :options="getComandosModuloPorMarca" 
                        v-model="comando" 
                        @input="verificarComandoSelecionado"                 
                        class="w-full text-center">
                    </v-select>       
                </div>
                <div class="vx-col w-1/2">                     
                    <div class="flex items-center">             
                        <vs-input class="w-full ml-2" v-model="valorParametro" :maxlength="2048" v-if="exibirParametro" :readonly="labelValorParametro1"/>                     
                    </div>
                    <label class="vs-input--label mr-2" v-if="exibirParametro">{{textoExplicativoParametro}}</label>  
                    <div v-if="labelValorParametro1" class="mb-2" >     
                        <div v-if="labelValorParametro1">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro1}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro1" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro2">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro2}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro2" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro3">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro3}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro3" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro4">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro4}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro4" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro5">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro5}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro5" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro6">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro6}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro6" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                        <div v-if="labelValorParametro7">
                            <label class="vs-input--label ml-2 mr-2">{{labelValorParametro7}}</label>        
                            <vs-input class="w-full ml-2" v-model="valorParametro7" :maxlength="400" v-on:keyup="concatenarValorParametro"/> 
                        </div>
                    </div>   
                </div>                
            </div>
        </vx-card>

        <vs-table :data="listaVeiculosParaEnviarComando" noDataText="Nenhum  veículo selecionado." stripe search>
        <template slot="header">
            <h6 class="mr-auto" v-if="listaVeiculosParaEnviarComando.length > 0 "> {{listaVeiculosParaEnviarComando.length}} veículo(s)</h6>                                   
        </template>
        <template slot="thead">
            <vs-th style="padding: 1px;"><span class="w-full" style="text-align:left !important;">Cliente</span></vs-th>            
            <vs-th><span class="w-full" style="text-align:left !important;"> Veículo </span></vs-th>            
            <vs-th><span class="w-full" style="text-align:left !important;"> Rastreador </span></vs-th>            
            <vs-th><span class="w-full" style="text-align:left !important;"> Operadora GSM </span></vs-th>            
            <vs-th></vs-th>            
        </template>

        <template slot-scope="{data}"> 
            <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                <vs-td class="p-0"><small>{{tr.nomeCliente}}</small></vs-td>
                <vs-td class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small> <br/> <small class="font-bold"> {{tr.placa}} </small></vs-td>
                 <vs-td class="p-0">
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-success" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold">{{tr.codigoModulo}}</small> <br/> <small>{{tr.marcaModulo}}</small>
                                <br v-if="tr.codigoModuloBkp"/>
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-warning" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold">{{tr.codigoModuloBkp}}</small> <br/> <small>{{tr.marcaModuloBkp}}</small>
                </vs-td>
                <vs-td class="p-0">
                    <small class="font-bold">{{tr.operadoraGSM}}</small>
                    <br/> <small>{{tr.numeroGSM}}</small>
                    <br v-if="tr.codigoModuloBkp"/>
                    <small class="font-bold" v-if="tr.codigoModuloBkp">{{tr.operadoraGSMBkp}}</small>
                    <br v-if="tr.codigoModuloBkp"/> <small v-if="tr.codigoModuloBkp">{{tr.numeroGSMBkp}}</small>
                </vs-td>
                <vs-td  style="padding: 1px;">
                    <vs-button size="small" color="danger" icon-pack="feather" icon="icon-minus" @click="removerOS(tr)"></vs-button>             
                </vs-td>
            </vs-tr>
        </template>
        </vs-table> 
          
       
        <vs-row vs-type="flex" vs-justify="space-between" class="mt-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                  <div>
                    <label class="vs-input--label ml-2 mr-2">Nome do Lote</label>         
                    <vs-input class="mr-2" v-model="nomeLote" :maxlength="255" /> 
                  </div>  
                  <div class="mt-5 flex">
                    <vs-button color="success" @click="enviarComandos" class="ml-2" :disabled="(comando && exibirParametro && !valorParametro) || !nomeLote || !comando || isSuporteInterno || !listaVeiculosParaEnviarComando.length"  icon="send"> </vs-button>
                    <vs-button color="danger" @click="limparListaSelecao" class="ml-2" :disabled="!listaVeiculosParaEnviarComando.length" icon="clear"> </vs-button>
                </div>
              </vs-col>
        </vs-row>
    </vs-popup> 

    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="p-1">
                <vs-row class="p-0">
                    <vs-col vs-align="center" vs-w="10">                     
                    <label class="vs-input--label">Cliente:</label>
                        <v-select  
                            label="nome"                                                                 
                            :options="listaClientes"  
                            :filter="pesquisarCliente"
                            v-model="filtro.cliente"                                                                          
                            class="w-full vs-input--label">
                            <template v-slot:option="option">                        
                                <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                            </template>
                        </v-select>  
                    </vs-col>

                    <vs-col vs-align="center" vs-w="2">                     
                            <label class="vs-input--label">Placa</label>
                            <vs-input v-model="filtro.placa" class="w-full mb-2" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" @keyup.enter="fetchListaVeiculos()" style="padding-left: 4px "/>
                    </vs-col>
                </vs-row>
                <vs-row class="p-0">                   
                    <vs-col vs-align="center" vs-w="2">                     
                        <label class="vs-input--label mr-2">Serial Módulo</label>
                        <vs-input v-model="filtro.serialModulo" class="w-full"  @keyup.enter="fetchListaVeiculos()" style="padding-left: 4px " /> 
                    </vs-col>                    
                    <vs-col vs-align="center" vs-w="3">                     
                        <label class="vs-input--label mr-2">Operadora</label>
                        <v-select                                               
                            :options="CONSTANTE_OPERADORAS_GSM" 
                            :reduce="r => r.data"                    
                            v-model="filtro.operadoraGSM"                                        
                            class="vs-input--label mt-1"
                            style="min-width: 220px ">
                        </v-select> 
                    </vs-col>
                    <vs-col vs-align="center" vs-w="3">                     
                        <label class="vs-input--label mr-2">Marca Módulo</label>
                        <v-select                                               
                            :options="CONSTANTE_MARCA_MODELOS_MODULOS" 
                            v-model="filtro.marcaModulo"                            
                            :reduce="r => r.data"   
                            class="vs-input--label mt-1"
                            style="min-width: 220px" >
                        </v-select> 
                    </vs-col>
                     <vs-col vs-align="center" vs-w="2">                     
                        <label class="vs-input--label mr-2">Tipo Modulo</label>
                        <vs-input v-model="filtro.tipoModulo" class="w-full" @keyup.enter="fetchListaVeiculos()" style="padding-left: 4px "/>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">  
                        <div class="flex mt-5">                    
                            <vx-tooltip text="Pesquisar os veículos">                
                                <vs-button size="small" class="ml-4" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchListaVeiculos()"></vs-button>
                            </vx-tooltip>
                            <feather-icon icon="ArchiveIcon" class="cursor-pointer ml-4 mr-6" :badge="listaVeiculosParaEnviarComando.length" @click="abrirPopUpSelecao" v-if="exibirExecucaoEmLote"/>  
                            <vs-button size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
                        </div>
                    </vs-col>
                </vs-row>
                
            </vx-card>
        </vs-col>
    </vs-row>    
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">           
            <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>
    <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe>
                <template slot="header">
                    <vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="adicionarTodos()" v-if="exibirExecucaoEmLote"></vs-button>             
                    <h6 class="ml-2" v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos</h6>      
                    <h6 class="ml-2" v-if="listaVeiculos.length > 0 "> 
                        [ 
                        <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="ml-2 mr-2"/> {{getQtdOnline}} online /
                        <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="ml-2 mr-2"/> {{listaVeiculos.length - getQtdOnline}} offline
                        ]
                    </h6>      
                    <p class="ml-4 text-sm" v-if="atualizadoAs">Pesquisado em <span class="font-medium">{{atualizadoAs}} </span></p>
                </template>
                <template slot="thead">
                    <vs-th v-if="exibirExecucaoEmLote"></vs-th>
                    <vs-th class="p-0">Cliente</vs-th>
                    <vs-th class="p-0">Veículo</vs-th>                    
                    <vs-th class="p-0">Código</vs-th>
                    <vs-th class="p-0">GSM</vs-th>
                    <vs-th class="p-0">Status</vs-th>                                
                    <vs-th class="p-0">Comunicação</vs-th>
                    <vs-th class="p-0">Ação </vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td style="padding: 1px;" v-if="exibirExecucaoEmLote">
                            <vs-button size="small" color="success" type="border" icon-pack="feather" icon="icon-plus" @click="adcionarOS(tr)"></vs-button>             
                        </vs-td>
                        <vs-td class="p-0"><small>{{tr.nomeCliente}}</small></vs-td>
                        <vs-td class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small> <br/> <small class="font-bold"> {{tr.placa}} </small></vs-td>
                        <vs-td class="p-0">
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-success" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold">{{tr.codigoModulo}}</small> <br/> <small>{{tr.marcaModulo}} - {{tr.tipoModulo}}</small>
                                <br v-if="tr.codigoModuloBkp"/>
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-warning" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold" v-if="tr.codigoModuloBkp">{{tr.codigoModuloBkp}}</small> <br v-if="tr.codigoModuloBkp"/> <small v-if="tr.codigoModuloBkp">{{tr.marcaModuloBkp}} - {{tr.tipoModuloBkp}}</small>
                        </vs-td>
                        <vs-td class="p-0">
                            <vx-tooltip :text="'Multioperadora:' + tr.operadoraGSMConectada ">                
                                <small class="font-bold">{{tr.operadoraGSM}}</small>
                                <br/> <small>{{tr.numeroGSM}}</small>
                            </vx-tooltip>
                            <small class="font-bold" v-if="tr.codigoModuloBkp">{{tr.operadoraGSMBkp}}</small>
                            <br v-if="tr.codigoModuloBkp"/> <small v-if="tr.codigoModuloBkp">{{tr.numeroGSMBkp}}</small>
                        </vs-td>
                        <vs-td class="p-0">
                            <div class="flex">
                                <vx-tooltip v-bind:text="'Veículo ligado  - voltagem ' + tr.ultimaVoltagem + 'v'">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-success" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual > 0" />
                                </vx-tooltip>
                                <vx-tooltip v-bind:text="'Veículo ligado e parado - voltagem ' + tr.ultimaVoltagem + 'v'">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-warning" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual === 0" />
                                </vx-tooltip>
                                <vx-tooltip v-bind:text="'Veículo desligado - voltagem ' + tr.ultimaVoltagem + 'v' ">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-danger" class="ml-2"  v-if="tr.ignicao === 'DESLIGADO'" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo bloqueado">                
                                    <feather-icon icon="LockIcon" svgClasses="w-3 h-3 stroke-current text-danger" class="ml-2"  v-if="tr.possuiBloqueio && tr.isBloqueado" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo desbloqueado">                
                                    <feather-icon icon="UnlockIcon" svgClasses="w-3 h-3 stroke-current" class="ml-2" v-if="tr.possuiBloqueio && !tr.isBloqueado" />
                                </vx-tooltip>
                                <vx-tooltip text="Sem alimentação externa">                
                                    <feather-icon icon="ZapOffIcon" svgClasses="w-3 h-3 stroke-current text-danger" class="ml-2"  v-if="tr.semAlimentacaoExterna" />
                                </vx-tooltip>  
                            </div>
                             <div class="flex">
                                <vx-tooltip text="Veículo ligado - Ignição Módulo de Backup">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-success" class="ml-2"  v-if="tr.ignicaoBkp === 'LIGADO' && tr.codigoModuloBkp" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo desligado - Ignição Módulo de Backup">               
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-danger" class="ml-2"  v-if="tr.ignicaoBkp === 'DESLIGADO' && tr.codigoModuloBkp" />
                                </vx-tooltip>                                 
                            </div>
                        </vs-td>
                       
                        <vs-td class="p-0">
                            <div class="flex">
                                <vx-tooltip :text="'Comunicação atualizada às ' + tr.dataUltimoPacoteRecebido">                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="mr-2" v-if=" tr.status === 'ON'" />
                                </vx-tooltip>  
                                <vx-tooltip v-bind:text="'Sem transmissão há ' + tr.tempoSemGPRS" >                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="mr-2" v-if="tr.status === 'OFF'" />
                                </vx-tooltip>    
                                <small :class="{ 'text-success': (tr.status === 'ON'), 'text-danger': (tr.status === 'OFF')} ">{{tr.dataUltimoPacoteRecebidoFmt2}}</small>
                            </div>
                            <div class="flex">
                                <vx-tooltip :text="'Comunicação atualizada às ' + tr.dataUltimoPacoteRecebidoBkp">                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="mr-2" v-if=" tr.statusBkp === 'ON' && tr.codigoModuloBkp" />
                                </vx-tooltip>  
                                <vx-tooltip v-bind:text="'Sem transmissão há ' + tr.tempoSemGPRSBkp" >                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="mr-2" v-if="tr.statusBkp === 'OFF' && tr.codigoModuloBkp" />
                                </vx-tooltip>    
                                <small v-if="tr.codigoModuloBkp" :class="{ 'text-success': (tr.statusBkp === 'ON'), 'text-danger': (tr.statusBkp === 'OFF')} ">{{tr.dataUltimoPacoteRecebidoBkp}}</small>
                            </div>
                            <vx-tooltip text="Última atualização (velocidade, ignição e/ou latitude/longitude)" >                
                                    <feather-icon icon="ActivityIcon" svgClasses="w-4 h-4 fill-current" class="mr-2" />
                                    <small>{{tr.dataUltimaAtualizacaoFmt2}}</small>
                            </vx-tooltip>
                        </vs-td>
                        <vs-td class="p-0">
                            <div class="flex">
                                <vx-tooltip text="Enviar Comandos">   
                                    <feather-icon icon="SendIcon" svgClasses="w-4 h-4 stroke-current text-primary" class="mr-2" @click="abrirPopUpEnviarComandos(tr)"/>
                                </vx-tooltip>                                                            
                                
                                <vx-tooltip text="Clique para visualizar o log de comunicação">   
                                    <feather-icon icon="ActivityIcon" svgClasses="w-4 h-4 stroke-current text-primary" class="mr-2" @click="abrirPopUpLogComunicacao(tr)" />
                                </vx-tooltip>                                
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts'
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import generico from "@/generico.js"
import EnviarComando from '@/views/components/EnvioComandos'
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'
import { CONSTANTE_MARCA_MODELOS_MODULOS } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_COMANDOS_TCP } from '@/constantesComboBox.js' 

const NAME_ROUTE_SEARCH = "backend-lote-comando-modulo-pesquisar";

export default {
    components: {
        flatPickr,
        EnviarComando,
        VueApexCharts
    },
    created() {
        this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
        this.CONSTANTE_MARCA_MODELOS_MODULOS = CONSTANTE_MARCA_MODELOS_MODULOS;      
        this.CONSTANTE_TIPOS_COMANDOS_TCP = CONSTANTE_TIPOS_COMANDOS_TCP;    
    },
    computed: {
        getQtdOnline() {
            let qtd = 0;
            if (this.listaVeiculos && this.listaVeiculos.length > 0) {
                for (var i=0; i < this.listaVeiculos.length; i++) {
                    if (this.listaVeiculos[i].status === 'ON') {
                        qtd++; 
                    }
                }
            }

            return qtd;
        },
        exibirExecucaoEmLote() {
            if ( this.$store.state.AppActiveUser.idEmpresa === 1) {
                return true;
            }

            if ( this.$store.state.AppActiveUser.perfil === 'EMPRESA' || this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR'  ||
                 this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT' ) {
                return true;   
           }

            return false;
        },
        getComandosModuloPorMarca() {
            
            if (this.listaVeiculosParaEnviarComando.length) {
                let comandos =  this.CONSTANTE_TIPOS_COMANDOS_TCP.filter( ({modulos}) => modulos.toUpperCase().indexOf(this.listaVeiculosParaEnviarComando[0].marcaModulo.toUpperCase()) >= 0);
                
                if (comandos) {
                    return comandos;
                }
            }            

            return [];
        }
    },
    data() {
        return {
            nomeLote: null,
            series: [ ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'rangeBar'
                },
                plotOptions: {
                    bar: { horizontal: true }
                },
                dataLabels: {                  
                    enabled: true,
                    formatter: function(val) {
                        var a = generico.zeroPad(new Date(val[0]).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(val[0]).getUTCMinutes(),2)
                        var b = generico.zeroPad(new Date(val[1]).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(val[1]).getMinutes(),2)                        
                        return a + ' - ' + b
                    }
                },
                fill: {
                    type: 'solid',
                    opacity: 0.8
                },
                xaxis: { type: 'datetime', min: null, max: null},
                legend: { position: 'top' }, 
                tooltip: {
                    custom: function(opts) {
                        var a = generico.zeroPad(new Date(opts.y1).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(opts.y1).getUTCMinutes(),2)
                        var b = generico.zeroPad(new Date(opts.y2).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(opts.y2).getUTCMinutes(),2)

                        return ('<b style="color: black"> Horário: ' +  a + ' às ' + b + ' </b> ');
                    }
                }               
            },
            listaVeiculosParaEnviarComando: [],
            filtro : { placa: null, cliente: null, serialModulo: null, operadoraGSM: null, marcaModulo: null, tipoModulo: null},
            atualizadoAs: null,
            exibirMensagemNenhumDadoEncontrado: false,            
            listaVeiculos: [],            
            dadosDaGridHistorico: [],
            popUpSelecao : {
                popupActive: false,
                exibirMensagem: false,
                mensagem: null          
            },
            popUpLogComunicacao: {
                exibir: false,
                veiculo: null,
                dataComunicacao: null
            },
            popUpHistoricoEventos: {
                exibir: false,
                veiculo: null,
                dataHoraPanico: null,
                titulo: null,
                tipoHistorico : null,
            },
            configDatePicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "U", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },
            logComunicacao: [],   
            listaAcessorios: [],         
            popUpAcessorios: {
                exibir: false,
                veiculo: null,
                acessorioSelecionado: null
            },
            popUpEnviarComando : {
                exibir: false,
                veiculo: null,
            },
            listaClientes: [],
            comando: null,
            valorParametro: null,
            exibirParametro: false,
            labelValorParametro: null,
            textoExplicativoParametro: null,
            labelValorParametro1: null,
            valorParametro1: null,
            labelValorParametro2: null,
            valorParametro2: null,
            labelValorParametro3: null,
            valorParametro3: null,
            labelValorParametro4: null,
            valorParametro4: null,
            labelValorParametro5: null,
            valorParametro5: null,
            labelValorParametro6: null,
            valorParametro6: null,
            labelValorParametro7: null,
            valorParametro7: null,
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        limparListaSelecao() {
            this.listaVeiculosParaEnviarComando = null;
            this.listaVeiculosParaEnviarComando = [];
            this.popUpSelecao.mensagem = null;
            this.popUpSelecao.exibirMensagem = false;
        },
        abrirPopUpSelecao() {
            this.popUpSelecao.popupActive = true;        
        },
        adicionarTodos() {
            for (var i= 0; i < this.listaVeiculos.length; i++){
                if (this.listaVeiculosParaEnviarComando.indexOf(this.listaVeiculos[i]) === -1) {
                    if (!this.listaVeiculosParaEnviarComando.length || (this.listaVeiculosParaEnviarComando.length && this.listaVeiculosParaEnviarComando[0].marcaModulo === this.listaVeiculos[i].marcaModulo)) {
                        this.listaVeiculosParaEnviarComando.push(this.listaVeiculos[i]);
                    } else {
                        this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Veículo ' + this.listaVeiculos[i].placa + ' com módulo diferente de ' + this.listaVeiculosParaEnviarComando[0].marcaModulo, acceptText:'OK' })         
                    }
                } 
            }
        },
        adcionarOS(pItem) {
            if (this.listaVeiculosParaEnviarComando.indexOf(pItem) === -1) {
                if (!this.listaVeiculosParaEnviarComando.length || (this.listaVeiculosParaEnviarComando.length && this.listaVeiculosParaEnviarComando[0].marcaModulo === pItem.marcaModulo)) {
                    this.listaVeiculosParaEnviarComando.push(pItem);
                } else {
                    this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Veículo com módulo diferente de ' + this.listaVeiculosParaEnviarComando[0].marcaModulo, acceptText:'OK' })         
                }
            } else {
                this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Veículo já incluído', acceptText:'OK' }) 
            }
        },
        removerOS(pItem) {
            this.listaVeiculosParaEnviarComando.splice(this.listaVeiculosParaEnviarComando.indexOf(pItem), 1);
        },        
        abrirPopUpEnviarComandos(tr) {
            this.popUpEnviarComando.exibir = true;
            this.popUpEnviarComando.veiculo = tr;
        },
        abrirPopUpLogComunicacao(tr) {
            this.popUpLogComunicacao.exibir = true;
            this.popUpLogComunicacao.veiculo = tr;
            this.popUpLogComunicacao.dataComunicacao = String(new Date().getTime() / 1000);
            this.fetchLogComunicacao();
        },      
        enviarComandos() {     
            if (!this.isValorParametroValido())  {
                this.$vs.notify({
                            title: "Mensagem", text: 'Parâmetro inválido',
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });    
                return;
            } 
            this.$vs.loading() 
            const params = new URLSearchParams();
            var listIdOS = null;

            for (var i= 0; i < this.listaVeiculosParaEnviarComando.length; i++){
                if (listIdOS === null) {
                    listIdOS = new String();
                    listIdOS = listIdOS + this.listaVeiculosParaEnviarComando[i].idVeiculo;
                } else {
                    listIdOS = listIdOS + "," + this.listaVeiculosParaEnviarComando[i].idVeiculo;
                }
            }
                        
            params.append('acao', "ENVIAR_COMANDO_EM_MASSA");
            params.append('nomeLote', this.nomeLote);
            params.append('idsVeiculos', listIdOS);
            
            if (this.comando.data === 'ENABLE_SEND_TO_RS232' || this.comando.data === 'DISABLE_SEND_TO_RS232') {
                params.append('comando', "COMANDO_GENERICO_TELTONIKA");
            } else {
                params.append('comando', this.comando.data);
            }

            if (this.valorParametro || this.valorParametro === 0) {
                params.append('valorParametro', this.valorParametro);
            }

            axios.post("/ListarVeiculosPorParametros", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.popUpSelecao.mensagem = response.data.mensagem;  
                    this.popUpSelecao.exibirMensagem = true;
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - enviarComandos - " + error); 
            })    
                                
        },          
        fetchListaVeiculos() {     

            if (!this.formularioValido()) {
                return;
            }

            let filtro = this.filtro;

            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            if (filtro.placa) { 
                params.append('placa', filtro.placa);
            }
            if (filtro.serialModulo) {
                params.append('serialModulo', filtro.serialModulo);
            }
            if (filtro.cliente) {
                params.append('nomeCliente', filtro.cliente.nome);
                params.append('idCliente', filtro.cliente.id);
                params.append('cnpjCPF', filtro.cliente.CNPJCPF);                
            }
            if (filtro.operadoraGSM) {
                params.append('operadoraGSM' , filtro.operadoraGSM);
            }
            if (filtro.marcaModulo) {
                params.append('marcaModulo' , filtro.marcaModulo);
            }

            if (filtro.tipoModulo) { 
                params.append('tipoModulo', filtro.tipoModulo);
            }
            
            axios.post("/ListarVeiculosPorParametros", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data;  
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchHistoricoErros() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('codigoModulo', this.popUpHistoricoEventos.veiculo.codigoModulo);
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHora', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarLogErroModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        fetchLogComunicacao() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpLogComunicacao.veiculo.idVeiculo);
            if (this.popUpLogComunicacao.dataComunicacao) {
                params.append('data', this.popUpLogComunicacao.dataComunicacao*1000);
            }

            axios.post("/ListarHistoricoComunicacaoModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                   this.logComunicacao.splice(0, this.logComunicacao.length);
                   let dados = response.data;

                   let dataFinal = new Date(Date.now());
                   let minimoValor = new Date(Date.now());
                   minimoValor.setUTCHours(0, 0);
                   this.chartOptions.xaxis.min = minimoValor.getTime();

                   let maximoValor = new Date(Date.now());
                   maximoValor.setUTCHours(23, 59);
                   this.chartOptions.xaxis.max = maximoValor.getTime();

                   let itemAnterior = null;
                   let dataInicial = null;
                   let dadosGrafico = [];

                   for(var j=0; j < dados.length; j++) {  
                       this.logComunicacao.push( { hora: dados[j].hora, status: dados[j].status });
                       
                       if (dataInicial === null) {
                           dataInicial = new Date(Date.now());
                           dataInicial.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                       }

                       if (itemAnterior  !== null && itemAnterior.status !== dados[j].status) {
                            dataFinal.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                            
                            if (itemAnterior.status === 1) {
                                dadosGrafico.push({ x: 'ONLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#00FF00'});
                            } else {
                                dadosGrafico.push({ x: 'OFFLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#ff0000'});
                            }

                            dataInicial = new Date(Date.now());
                            dataInicial.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                       }

                       itemAnterior = dados[j];
                   }

                   dataFinal.setUTCHours(itemAnterior.hora.split(":")[0], itemAnterior.hora.split(":")[1]);
                   if (itemAnterior.status === 1) {
                       dadosGrafico.push({ x: 'ONLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#00FF00'});
                   } else {
                       dadosGrafico.push({ x: 'OFFLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#ff0000'});
                   }

                   this.series.push({data: dadosGrafico});
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
        },  
        formularioValido() {            
            if (!this.filtro.cliente && !this.filtro.marcaModulo) { 
                this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Informe o nome do cliente ou a marca dos módulos.', acceptText:'OK' })         
                return false;
            }
        
            return true;
        },
        verificarComandoSelecionado() {
            if (!this.comando) {
                this.exibirParametro = false;
                this.labelValorParametro1 = null;
                this.labelValorParametro2 = null;
                this.labelValorParametro3 = null;
                this.labelValorParametro4 = null;
                this.labelValorParametro5 = null;
                this.labelValorParametro6 = null;
                this.labelValorParametro7 = null;
                
                this.valorParametro1 = null;
                this.valorParametro2 = null;
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                return;
            }

            let comandoSelecionado = this.comando.data;

            if (comandoSelecionado && (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SET_HOURMETER' 
                || comandoSelecionado === 'SPC_SET_HOURMETER' ||  comandoSelecionado === 'BIN_PARAM_TEMPO_PARA_FALHA_GPRS' || comandoSelecionado === 'SPC_SET_ODOMETER' 
                || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' || comandoSelecionado === 'APPLICATION_TIMING_PARAMETERS' || comandoSelecionado === 'SPC_GSR_DEB_STOPPED' 
                || comandoSelecionado === 'SPC_GSR_DEB_MOVING'  || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SPC_SET_ODORPM_ENABLE')
                || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'SET_HOURMETER_VALUE' 
                || comandoSelecionado === 'GT_SETODOMETER' || comandoSelecionado === 'GT_SETHOURMETER'
                || comandoSelecionado === 'GTIDA_ENABLE' || comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' 
                || comandoSelecionado === 'SPC_ALLOW_ANY_TAG_ACC' || comandoSelecionado === 'SPC_ZIG_ACC_ENCRYPT_KEY' || comandoSelecionado === 'MILEAGE'
                || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_SAT' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_PAN_SAT' 
                || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_OFF' || comandoSelecionado === 'COMANDO_GENERICO' || comandoSelecionado === 'COMANDO_GENERICO_ST'
                || comandoSelecionado === 'COMANDO_GENERICO_TELTONIKA' || comandoSelecionado === 'TIMER' || comandoSelecionado === 'COMANDO_GENERICO_GLOBALPOSITION'
                || comandoSelecionado === 'E3_COMANDO_GENERICO' || comandoSelecionado === 'E3_TEMPO_COMUNICACAO' || comandoSelecionado === 'SETPARAM_ODOMETRO'
                || comandoSelecionado === 'SEND_MESSAGE_RS232'  || comandoSelecionado === 'COMANDO_GENERICO_GT' || comandoSelecionado === 'SEND_TO_RS232'
                || comandoSelecionado === 'COMANDO_GENERICO_QUANTA' || comandoSelecionado === 'ENABLE_SEND_TO_RS232' || comandoSelecionado === 'DISABLE_SEND_TO_RS232'
                || comandoSelecionado === 'SEND_TO_RS232_GT' || comandoSelecionado === 'COMANDO_GENERICO_JIMIHUB') {
                
                this.exibirParametro = true;
                this.valorParametro = null;
                this.labelValorParametro1 = null;
                this.labelValorParametro2 = null;
                this.labelValorParametro3 = null;
                this.labelValorParametro4 = null;
                this.labelValorParametro5 = null;
                this.labelValorParametro6 = null;
                this.labelValorParametro7 = null;
                
                this.valorParametro1 = null;
                this.valorParametro2 = null;
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                
                if (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SPC_SET_ODOMETER' || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' 
                    || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'GT_SETODOMETER' || comandoSelecionado === 'MILEAGE'
                    || comandoSelecionado === 'SETPARAM_ODOMETRO') {
                    this.labelValorParametro = "Odômetro em KM";
                } else if (comandoSelecionado === 'SET_HOURMETER' || comandoSelecionado === 'SPC_SET_HOURMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SET_HOURMETER_VALUE'
                    || comandoSelecionado === 'GT_SETHOURMETER') { 
                    this.labelValorParametro = "Horímetro em Minutos";
                } else if ( comandoSelecionado === 'SPC_SET_ODORPM_ENABLE' ){
                    this.labelValorParametro = "0:desativar, 1 odometro, 2 RPM, 3 ambos";
                } else if ( comandoSelecionado === 'GTIDA_ENABLE' ){
                    this.labelValorParametro = "Informe os IDs dos cartões separados por vírgula";
                } else if ( comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' ){
                    this.labelValorParametro = "Tempo em horas. 0 desativa o sleep.";
                    this.valorParametro = "1";
                } else if ( comandoSelecionado == 'SPC_ALLOW_ANY_TAG_ACC' ){
                    this.labelValorParametro = "0 - Desabilitar. 1 - para Habilitar";
                    this.valorParametro = "1";
                } else if ( comandoSelecionado === 'SPC_ZIG_ACC_ENCRYPT_KEY' ){
                    this.labelValorParametro = "Chave de criptografia com 32 caracteres.";
                    this.valorParametro = "2102030405060708090a0b0c0d0e0f01";
                } else if (comandoSelecionado === 'COMANDO_GENERICO' || comandoSelecionado === 'COMANDO_GENERICO_ST' || comandoSelecionado === 'COMANDO_GENERICO_TELTONIKA'
                || comandoSelecionado === 'COMANDO_GENERICO_GLOBALPOSITION' || comandoSelecionado === 'E3_COMANDO_GENERICO' || comandoSelecionado === 'SEND_MESSAGE_RS232'  || comandoSelecionado === 'COMANDO_GENERICO_GT'
                || comandoSelecionado === 'SEND_TO_RS232' || comandoSelecionado === 'COMANDO_GENERICO_QUANTA' || comandoSelecionado === 'ENABLE_SEND_TO_RS232' || comandoSelecionado === 'DISABLE_SEND_TO_RS232' 
                || comandoSelecionado === 'SEND_TO_RS232_GT' || comandoSelecionado === 'COMANDO_GENERICO_JIMIHUB') {
                    this.labelValorParametro = "Cole o comando no campo parâmetro. Utilize <CODIGO_MODULO> como coringa.";
                } else {
                    this.labelValorParametro = "Informe o parâmetro";
                }
                
                if (comandoSelecionado === 'SPC_GSR_DEB_STOPPED') {
                    this.valorParametro = "120";
                }
                if (comandoSelecionado === 'SPC_GSR_DEB_MOVING') {
                    this.valorParametro = "5";
                }
                
                if (comandoSelecionado === 'BIN_PARAM_TEMPO_PARA_FALHA_GPRS' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_SAT' ||
                    comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_PAN_SAT' || comandoSelecionado === 'BIN_PARAM_TEMPO_TRANS_IGN_OFF') {
                    this.valorParametro = "600";
                }

                if (comandoSelecionado === 'ENABLE_SEND_TO_RS232') {
                    this.valorParametro = "setparam 151:7";
                } 
                if (comandoSelecionado === 'DISABLE_SEND_TO_RS232') {
                    this.valorParametro = "setparam 151:11";
                }

            } else  if (comandoSelecionado && comandoSelecionado === 'REPORTING') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Intervalo de envio parado";
                this.labelValorParametro2 = "Intervalo de envio em movimento";
                this.labelValorParametro3 = "Intervalo de envio em emergência";
                this.labelValorParametro4 = "Numero de envio de emergência";
                this.labelValorParametro5 = "Distância para envio de posições";
                this.labelValorParametro6 = "Intervalo de envio de Keep Alive";
                this.labelValorParametro7 = null;
                
                this.valorParametro1 = "300";
                this.valorParametro2 = "60";
                this.valorParametro3 = "60";
                this.valorParametro4 = "1";
                this.valorParametro5 = "500";
                this.valorParametro6 = "0";
                this.valorParametro7 = null;
                
                this.labelValorParametro = "Parametro total";
                this.concatenarValorParametro();
            } else if (comandoSelecionado && comandoSelecionado === 'NEW_PARAMETER_SETTING') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Ângulo para envio de posição";
                this.labelValorParametro2 = "FIFO/LIFO";
                this.labelValorParametro3 = "Intervalo para acionar botão anti-furto";
                this.labelValorParametro4 = "Sensibilidade para movimento";
                this.labelValorParametro5 = "Delay para detectar movimento";
                this.labelValorParametro6 = "Porcentagem modulo em movimento";
                this.labelValorParametro7 = "Habilitar horímetro";	
                
                this.valorParametro1 = "10";
                this.valorParametro2 = "0";
                this.valorParametro3 = "30";
                this.valorParametro4 = "3";
                this.valorParametro5 = "3";
                this.valorParametro6 = "70";
                this.valorParametro7 = "1";
                
                this.labelValorParametro = "Parâmetro total";
                this.concatenarValorParametro();
                
            } else if (comandoSelecionado && comandoSelecionado === 'PARAMETER_VARIOUS_FEATURES') {
                this.exibirParametro = true;
                this.labelValorParametro1 = "Velocidade Máxima";
                this.labelValorParametro2 = "Tempo Sleep";
                this.labelValorParametro3 = null;				
                this.labelValorParametro4 = null;				
                this.labelValorParametro5 = null;				
                this.labelValorParametro6 = null;				
                this.labelValorParametro7 = null;				
                
                this.valorParametro1 = "120";
                this.valorParametro2 = "2";
                this.valorParametro3 = null;
                this.valorParametro4 = null;
                this.valorParametro5 = null;
                this.valorParametro6 = null;
                this.valorParametro7 = null;
                
                this.labelValorParametro = "Parâmetro total";   
                this.concatenarValorParametro();             
            } else {
                this.exibirParametro = false;
                this.valorParametro = null;
            }
        },
        concatenarValorParametro() {
            let comandoSelecionado = this.comando.data;
            if (comandoSelecionado && comandoSelecionado === 'REPORTING') {
                this.valorParametro = this.valorParametro1 + ";" + this.valorParametro2 + ";" + 
                this.valorParametro3 + ";" + this.valorParametro4 + ";" +
                    this.valorParametro5 + ";" + this.valorParametro6 + ";0;0;" + this.valorParametro6;
            } else if (comandoSelecionado && comandoSelecionado === 'NEW_PARAMETER_SETTING') {
                this.valorParametro = this.valorParametro1 + ";" + this.valorParametro2 + ";" + this.valorParametro3 + ";0;0;0;0;" 
                + this.valorParametro4 + ";" + this.valorParametro5 + ";" +
                    this.valorParametro6 + ";5;10;70;" + this.valorParametro7;
            } else if (comandoSelecionado && comandoSelecionado === 'PARAMETER_VARIOUS_FEATURES') {
                this.valorParametro = "02;1;" + this.valorParametr1 +";" + this.valorParametro2 + ";0;0;0;1;0;1;7;0;1;0";		
            }
        },
        isValorParametroValido() {
            let comandoSelecionado = this.comando.data;
            if (comandoSelecionado === 'SET_ODOMETER' || comandoSelecionado === 'SPC_SET_ODOMETER' || comandoSelecionado === 'SETUP_ODOMETER_GPS_TRACE' 
                    || comandoSelecionado === 'SETODOMETER' || comandoSelecionado === 'MILEAGE_CORRECTION' || comandoSelecionado === 'GT_SETODOMETER' 
                    || comandoSelecionado === 'MILEAGE' || comandoSelecionado === 'SETPARAM_ODOMETRO'
                ){
                
                if (!this.valorParametro) {
                    return false;
                } else if (isNaN(Number(this.valorParametro))) {                    
                    return false;
                }
            } else if (comandoSelecionado === 'SET_HOURMETER' || comandoSelecionado === 'SPC_SET_HOURMETER' || comandoSelecionado === 'SETHMETER' || comandoSelecionado === 'SET_HOURMETER_VALUE'
                    || comandoSelecionado === 'GT_SETHOURMETER' || comandoSelecionado === 'SPC_SET_ODORPM_ENABLE' || comandoSelecionado === 'SPC_KEEP_WORKING_BF_SLEEP' 
                    || comandoSelecionado === 'SPC_GSR_DEB_STOPPED' || comandoSelecionado === 'SPC_GSR_DEB_MOVING'  ) {
                 if (!this.valorParametro) {
                    return false;
                } else if (isNaN(parseInt(this.valorParametro))) {                    
                    return false;
                } else {
                    this.valorParametro = parseInt(this.valorParametro);
                }
            }
                
            return true;
        },
        voltar() {
            this.$router.push({
                name: NAME_ROUTE_SEARCH                     
            })
        },      
    },
    mounted() {               
        console.log("this.$store.state.AppActiveUser.perfil=" + this.$store.state.AppActiveUser.perfil);
        this.fetchListaClientes();
        if (this.$store.getters.filtroRelatorioVeiculo) {
           this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroRelatorioVeiculo));           
        }
    },
    beforeDestroy() {    
        this.$store.dispatch('setFiltroRelatorioVeiculo', this.filtro);
    }
}
</script>

<style scoped>
    .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
    .tabela2ComScroll {      
      overflow: auto;  
      height: calc(100vh - 600px);
      position: relative;      
    } 
</style>

<style lang="scss">  
.con-vs-popup {    
    .vs-popup {
      width: 800px;      
    }
}
</style>